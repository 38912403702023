.text_of_sec12 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.div_of_contactUs {
  width: 70%;
  height: 80%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

.div_of_contactUs > h1 {
  font-size: 25px;
  font-family: "Be Viettnam Pro SemiBold";
  color: black;
}
.div_of_contactUs > p {
  width: 70%;
  font-size: 15px;
  font-family: "Be Viettnam Pro Regular";
  color: black;
  text-align: center;
  line-height: 25px;
}
.div_of_contactUs > a {
  width: 70%;
  font-size: 18px;
  font-family: "Be Viettnam Pro Regular";
  color: black;

  line-height: 45px;
  text-decoration: none;
}
@media screen and (max-width: 766px) {
  .div_of_contactUs > a {
    width: 100%;
    font-size: 18px;
    font-family: "Be Viettnam Pro Regular";
    color: black;

    line-height: 45px;
    text-decoration: none;
  }
}
