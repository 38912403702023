.main_sec10_div {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.sub_main_div_sec10 {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
}
.image_of_sec10 {
  width: 100%;
  height: 100%;
}
.image_of_sec10 > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.text_of_sec10 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.inner_div_text_sec10 {
  background-color: #06b0ff;
  border-radius: 12px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.inner_div_text_sec10 > h1 {
  font-size: 25px;
  font-family: "Be Viettnam Pro SemiBold";
  color: white;
}
.inner_div_text_sec10 > p {
  width: 70%;
  font-size: 15px;
  font-family: "Be Viettnam Pro Regular";
  color: white;
  text-align: center;
  line-height: 25px;
}
.inner_div_text_sec10 > p > a {
  width: 70%;
  font-size: 15px;
  font-family: "Be Viettnam Pro Regular";
  color: white;
  text-align: center;
  line-height: 25px;
  text-decoration: none;
}
@media screen and (max-width: 767px) {
  .inner_div_text_sec10 {
    width: 100%;
    height: 100% !important;
    padding: 15px 0px;
  }
  .inner_div_text_sec10 > h1 {
    font-size: 22px;
    font-family: "Be Viettnam Pro SemiBold";
    color: white;
    margin-bottom: 10px;
  }
  .sub_main_div_sec10 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column-reverse;
  }
  .inner_div_text_sec10 > p {
    width: 90% !important;
    font-size: 12px !important;
    font-family: "Be Viettnam Pro Regular";
    color: white;
    text-align: center;
    line-height: 20px !important;
    margin: 0;
  }
  .inner_div_text_sec10 > p > a {
    width: 90% !important;
    font-size: 12px !important;
    font-family: "Be Viettnam Pro Regular";
    color: white;
    text-align: center;
    line-height: 20px !important;
    text-decoration: none;
  }
}
