.main_sec9_div {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.sub_main_div_sec9 {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
}
.image_of_sec9 {
  width: 100%;
  height: 100%;
}
.image_of_sec9 > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.text_of_sec9 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.inner_div_text_sec9 {
  background-color: #06b0ff;
  border-radius: 12px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.inner_div_text_sec9 > h1 {
  font-size: 25px;
  font-family: "Be Viettnam Pro SemiBold";
  color: white;
}
.inner_div_text_sec9 > p {
  width: 70%;
  font-size: 15px;
  font-family: "Be Viettnam Pro Regular";
  color: white;
  text-align: center;
  line-height: 25px;
}

@media screen and (max-width: 766px) {
  .sub_main_div_sec9 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .inner_div_text_sec9 > p {
    width: 90%;
    font-size: 15px;
    font-family: "Be Viettnam Pro Regular";
    color: white;
    text-align: center;
    line-height: 25px;
  }
}
