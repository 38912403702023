.Main_Footer_div {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
}
.sub_main_div_of_Footer {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #06b0ff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  padding: 5rem 0 0 0;
}
.main_store_img_div_footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -5%;
  gap: 1rem;
}
.div_oficones {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}
.div_polcys_footer {
  width: 90%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  gap: 1rem;
}
.link_of_footer {
  text-decoration: none;
  font-size: 21px;
  font-family: "Be Viettnam Pro SemiBold";
  color: white;
  text-align: center;
}
.footer_data_of_text {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.text_div_of_footer > p {
  font-size: 15px;
  font-family: "Be Viettnam Pro Regular";
  color: white;

  line-height: 25px;
}
.logo_div_footer {
}
.logo_img_f {
  width: 150px;
  height: 150px;
  overflow: hidden;
}
.logo_img_f > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
