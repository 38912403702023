.MAIN_home {
  overflow: hidden;
}

@media screen and (max-width: 766px) {
  .sub_main_div_sec2 {
    height: 100% !important;
  }
  .main_text_sec2 {
    padding: 2rem 0 !important;
    width: 90% !important;
  }
  .main_store_img_div {
    width: 80% !important;

    top: -6% !important;
    /* background-color: aqua; */
  }
  .sub_main_div_sec3 {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr !important;
    grid-template-rows: 50% 50% !important;
  }
}
