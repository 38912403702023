.main_sec1_div {
  margin-top: 7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.submain_div {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.img_div_main {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.phone_img {
  width: 90%;
  height: 60%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.phone_img > img {
  width: 70%;
  height: 70%;
  object-fit: cover;
}
