.main_div_section2 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5rem;
}

.main_store_img_div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.sub_main_div_sec2 {
  width: 90%;
  height: 300px;
  background-color: #06b0ff;
  border-radius: 18px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.main_store_img_div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -8%;
  /* background-color: aqua; */
}

.img_store {
  width: 200px;
  height: 100px;
  overflow: hidden;
}
.img_store > img {
  width: 100%;

  border-radius: 20px;
  object-fit: contain;
}

.main_text_sec2 {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.main_text_sec2 > h1 {
  font-size: 25px;
  font-family: "Be Viettnam Pro SemiBold";
  color: white;
}
.main_text_sec2 > p {
  font-size: 15px;
  font-family: "Be Viettnam Pro Regular";
  color: white;
  text-align: center;
  line-height: 25px;
}
